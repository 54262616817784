import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/observable/of';

import {AppSettings} from '../../config/app.settings';

let counter = 0;

declare var php_crud_api_transform: any;

@Injectable()
export class UserService {

  url: string = AppSettings.API_URL + '/php-crud-api/api.php/user'; // 'http://yagna-local/php-crud-api/api.php/user';
  user: any;
  public sessionToken: string;
  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };

  private userArray: any[];

  constructor(private http: HttpClient) {
    // this.userArray = Object.values(this.users);
  }

  getUsers(): Observable<any> {
    return Observable.of(this.users);
  }

  getUserArray(): Observable<any[]> {
    return Observable.of(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return Observable.of(this.userArray[counter]);
  }

  getPortalUser(email: string, sessionToken: string): Observable<any> {
       const userUrl = AppSettings.API_URL + '/php-crud-api/api.php/user?token=' +  sessionToken;
       return this.http.get(userUrl + '&filter=email,eq,' + email);

  }

  getSessionToken(jwtToken: string): Observable<any> {
       const userUrl = AppSettings.API_URL + '/php-crud-api/api.php/';
       const headers = new HttpHeaders();
       headers.append('Content-Type', 'application/x-www-form-urlencoded');

      const field = {token: jwtToken};
      return this.http.post(userUrl, this.getFormUrlEncoded(field));

       // return this.http.post(userUrl, {token: jwtToken});

  }

  getFormUrlEncoded(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
	}

  extractUser(res: Response) {
		    const body = res.json();
        // if(body.user.records[0])
        //     this.userId = body.user.records[0][0];
        const resp = php_crud_api_transform(body);
        this.user = resp.user[0];
        // if ( this.user )
        //     this.cookieService.put("USER",resp.user[0].email);
        return  resp.user[0]; // body.user.records[0]

	}

    catchError(err: any): Observable<any> {
        // this.cookieService.remove("XSRF-TOKEN");
		    const msg = (err.message) ? err.message :
					(err.status) ? err.status :
						'Failed to retrieve Users from server!';
		     return msg;
	}
}
